import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'https://web-api-sgp.piebarometre.digital/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  },
})

export default {
  getParamsDI () {
    return apiClient.get('api/request-parametre-intervention/get-parametrage')
  },
  createParamsDI (ParamsDI) {
    const formData = new FormData()
    formData.append('libelle_parametrage_domaine_interventions', ParamsDI.libelle_parametrage_domaine_interventions)
    return apiClient.post('api/request-parametre-intervention/add-parametrage', formData)
  },

  updateParamsDI (ParamsDI) {
    const formData = new FormData()
    formData.append('libelle_parametrage_domaine_interventions', ParamsDI.libelle_parametrage_domaine_interventions)
     return apiClient.post(`api/request-parametre-intervention/update-parametrage/${ParamsDI.id}`, formData)
  },

  deleteParamsDI (ParamsDI) {
    return apiClient.post(`api/request-parametre-intervention/delete-parametrage/${ParamsDI.id}`)
 },
}
